import React, { useEffect, useState, useRef } from "react";
import Header from "../components/header";
import { useUser } from "../contexts/UserProvider";
import axios from "axios";
import { apiurl } from "../config/config";
import { toast } from "react-toastify";
import LoaderSpiner from "../components/loader";
import { useNavigate, useSearchParams } from "react-router-dom";
import { awsKeyId, awsSecretKey } from "../config/config";
import Cropper from "react-easy-crop";
import { getCroppedImg } from "../components/imageCrop/cropImageHelper";
import { v4 as uuidv4 } from "uuid";
import { getCookie } from "../config/coockies";
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from "@mui/material/CircularProgress";
const aws = require("aws-sdk");

const CreateCommunities = () => {
  const token = getCookie("savage_token");
  const { userData } = useUser();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const communityHandle = searchParams.get("handle");
  const cid = searchParams.get("cid");

  const [formData, setFormData] = useState({
    title: "",
    about: "",
    hero_img: "",
    cover_img: "",
    // community: "",
    communityType: "",
    discloseauthorname:false
  });

  const [protectedpassword, setProtectedPassword] = useState("");
  const [isProtected, setIsProtected] = useState(false);

  const initialError = {
    title: false,
    about: false,
    hero_img: false,
    cover_img: false,
    // community: false,
    communityType: false,
    protectedpassword: false,
    discloseauthorname: false
  };
  const [error, setError] = useState(initialError);
  const [loading, setLoading] = useState(false);
  const [imgloading, setImgLoading] = useState({
    image1: false,
    image2: false,
  });
  const [profileImgCrop, setProfileImgCrop] = useState(false);
  const [coverImgCrop, setCoverImgCrop] = useState(false);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedProfileImg, setCroppedProfileImg] = useState(null);
  const [croppedCoverImg, setCroppedCoverImg] = useState(null);
  const [cropImageType, setCropImageType] = useState(""); 
  const [crop, setCrop] = useState({ x: 0, y: 0 }); 
  const [zoom, setZoom] = useState(1);
  const [rotation, setRotation] = useState(0);
  const [isUpload, setIsUpload] = useState(false);
  const coverFileInputRef = useRef(null);
  const heroFileInputRef = useRef(null);

  const handleChange = (e) => {
    const { name, value, type, checked, } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: type === "checkbox" ? checked : value, // Handle checkbox toggle
    }));
    setError((prevData) => ({ ...prevData, [name]: false }));
    if (name === "communityType") {
      setIsProtected(value === "protected");
    }
  };

  const validateForm = () => {
    const newErrors = { ...initialError };
    let hasErrors = false;
    Object.keys(formData).forEach((field) => {
      if (formData[field] === "") {
        newErrors[field] = true;
        hasErrors = true;
      }
    });
    setError(newErrors);
    return !hasErrors;
  };

  const ImageHandler = async (file) => {
    setImgLoading({ ...imgloading, [cropImageType === "hero_img" ? "image1" : "image2"]: true });

    try {
      let myFile = file.name.split(".");
      let myFileType = myFile[myFile.length - 1];
      const s3 = new aws.S3({
        accessKeyId: awsKeyId,
        secretAccessKey: awsSecretKey,
      });
      const params = {
        Bucket: "savnewstorage",
        Key: `${uuidv4()}.${myFileType}`,
        Body: file,
        ContentType: file.type,
      };
      let { Location } = await s3.upload(params).promise();

      setImgLoading({ ...imgloading, [cropImageType === "hero_img" ? "image1" : "image2"]: false });
      return Location;
    } catch (exception) {
      console.error(exception);
      setImgLoading({ ...imgloading, [cropImageType === "hero_img" ? "image1" : "image2"]: false });
      return false;
    }
  };

  const handleProfileFileChange = (type, e) => {
    const file = e.target.files[0];
    if (file) {
      const img = new Image();
      img.src = URL.createObjectURL(file);
      img.onload = () => {
        setCroppedProfileImg(URL.createObjectURL(file));
        setCropImageType(type);
        setProfileImgCrop(true);
      };
    }
  };

  const handleCancleCoverCrop = () => {
    setCoverImgCrop(false);
    coverFileInputRef.current.value = "";
    setFormData((prev) => ({
      ...prev,
      cover_img: "", 
    }));
  }
  const handleCancleProfileCrop = () => {
    setProfileImgCrop(false);
    heroFileInputRef.current.value = "";
    setFormData((prev) => ({
      ...prev,
      hero_img: "", 
    }));
  }

  const handleCoverFileChange = (type, e) => {
    const file = e.target.files[0];
    if (file) {
      const img = new Image();
      img.src = URL.createObjectURL(file);
      img.onload = () => {
        setCroppedCoverImg(URL.createObjectURL(file));
        setCropImageType(type);
        setCoverImgCrop(true);
      };
    }
  };

  const showCroppedProfileImage = async () => {
    setIsUpload(true);
    try {
      const croppedImage = await getCroppedImg(croppedProfileImg, croppedAreaPixels, rotation);
      const file = new File([croppedImage], "cropped_image.jpeg", { type: "image/jpeg" });

      const imageUrl = await ImageHandler(file);
      if (imageUrl) {
      setIsUpload(false);
      setFormData((prev) => ({
        ...prev,
        [cropImageType]: imageUrl, 
      }));

    }

    setProfileImgCrop(false);
    setCroppedProfileImg(null);
    setZoom(1); 
    setRotation(0) 
    } catch (e) {
      console.error(e);
      setIsUpload(false)
    }
  };

  const showCroppedCoverImage = async () => {
    setIsUpload(true);
    try {
      const croppedImage = await getCroppedImg(croppedCoverImg, croppedAreaPixels, rotation);
      const file = new File([croppedImage], "cropped_image.jpeg", { type: "image/jpeg" });

      const imageUrl = await ImageHandler(file);
      if (imageUrl) {
        setIsUpload(false);
      setFormData((prev) => ({
        ...prev,
        [cropImageType]: imageUrl, 
      }));
    }

    setCoverImgCrop(false);
    setCroppedCoverImg(null);
    setZoom(1); // Reset zoom
    setRotation(0) // Close the cropping modal after uploading
    } catch (e) {
      console.error(e);
      setIsUpload(false);
    }
  };
  
  const handleSubmitCommunitie = async () => {
    setLoading(true);
    try {
      if (validateForm() && (!isProtected || protectedpassword !== "")) {
        const createdata = {
          title: formData.title,
          about: formData.about,
          hero_img: formData.hero_img,
          cover_img: formData.cover_img,
          // community: formData.community,
          communityType: formData.communityType,
          password: protectedpassword,
          author: {
            _id: userData._id,
            username: `${userData.firstname} ${userData.lastname}`,
          },
          members: [
            {
              _id: userData._id,
              username: `${userData.firstname} ${userData.lastname}`,
              accountType: "admin",
            },
          ],
          discloseauthorname:formData.discloseauthorname
        };

        const response = await axios.post(
          `${apiurl}/community/create`,
          createdata,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: token,
            },
          }
        );
        
        if (response.status === 200) {
          setLoading(false);
          setFormData({
            title: "",
            about: "",
            hero_img: "",
            cover_img: "",
            // community: "",
            communityType: "",
            discloseauthorname :false
          });
          setProtectedPassword("");
          navigate("/communities");
          toast.success("Community Created Successfully");
        } else {
          setLoading(false);
          toast.error("data post failed");
        }
      } else {
        setLoading(false);
        toast.error("Please fill in all required fields.");
      }
    } catch (error) {
      setLoading(false);
      console.error("Create Community Error:", error);
    }
  };

  const handleGetCommunity = async () => {
    try {
      setLoading(true);
      const response = await axios.post(
        `${apiurl}/community/handle/${communityHandle}`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      if (response.data.success === true) {
        setLoading(false);
        setFormData(response.data.community);
      } else {
        setLoading(false);
        console.error("Error: Unable to retrieve community data");
      }

      // console.log('get community response', response)
    } catch (error) {
      setLoading(false);
      console.error("get community error", error);
    }
  };

  const handleUpdateCommunitie = async () => {
    setLoading(true);
    try {
      if (validateForm() && (!isProtected || protectedpassword !== "")) {
        const updatedData = {
          title: formData.title,
          about: formData.about,
          hero_img: formData.hero_img,
          cover_img: formData.cover_img,
          // community: formData.community,
          communityType: formData.communityType,
          password: protectedpassword,
          author: {
            _id: userData._id,
            username: `${userData.firstname} ${userData.lastname}`,
          },
          members:formData.members,
          discloseauthorname: formData.discloseauthorname
        };
        // console.log('updatedData  >>>>>>>-----', updatedData);
        const response = await axios.put(
          `${apiurl}/community/update-community/${cid}`,
          updatedData,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: token,
            },
          }
        );
        // console.log('update community response', response)
        if (response.data.success === true) {
          setLoading(false);
          toast.success(response.data.message);
          navigate(-1);
        } else {
          setLoading(false);
          toast.error("Error: Unable to update community data");
        }
      } else {
        setLoading(false);
        toast.error("Please fill in all required fields.");
      }
    } catch (error) {
      setLoading(false);
      console.error("update community error", error);
    }
  };

  useEffect(() => {
    if (
      communityHandle !== undefined &&
      communityHandle !== null &&
      communityHandle !== ""
    ) {
      handleGetCommunity();
    }
  }, [communityHandle]);

  return (
    <>
      {loading === true ? <LoaderSpiner text="Loading ..." /> : null}
      <Header />
      <div className="inset-0 flex items-center justify-center bg-black bg-opacity-50">
        <div
          className="bg-cover bg-center bg-black bg-opacity-50 w-full h-full px-4"
          style={{ backgroundImage: 'url("assets/communitiesbnr1.jpg")' }}
        >
          <div className="bg-white sm:p-8 p-4 rounded shadow w-full sm:w-5/12 m-auto my-4">
            <h2 className="text-2xl font-semibold mb-4">{communityHandle?"Update Community":"Create Community"}</h2>
            <div className="mb-4 relative">
              <label
                className="text-gray-700 text-sm font-bold mb-2 flex justify-start"
                htmlFor="title"
              >
                Community Title <span className="text-danger">*</span>
                {error.title && (
                  <p className="text-[#FF0000] sm:text-sm text-xs absolute  right-3">
                    Title is mandatory *
                  </p>
                )}
              </label>
              <input
                className="w-full border border-gray-300 p-2 rounded-md focus:outline-none focus:border-blue-500"
                type="text"
                placeholder="community title"
                name="title"
                value={formData.title}
                onChange={(e) => handleChange(e)}
                onBlur={validateForm}
              />
            </div>

            <div className="mb-4 relative">
              <label
                className="text-gray-700 text-sm font-bold mb-2 flex justify-start"
                htmlFor="about"
              >
                Community About <span className="text-danger">*</span>
                {error.about && (
                  <p className="text-[#FF0000] sm:text-sm text-xs absolute right-3">
                    About is mandatory *
                  </p>
                )}
              </label>
              <input
                className="w-full border border-gray-300 p-2 rounded-md focus:outline-none focus:border-blue-500"
                type="text"
                placeholder="community about"
                name="about"
                value={formData.about}
                onChange={(e) => handleChange(e)}
              />
            </div>

            <div className="mb-4 relative">
              <label
                className="text-gray-700 text-sm font-bold mb-2 flex justify-start"
                htmlFor="heroimage"
              >
                Profile Image <span className="text-danger">*</span>
                {!formData.hero_img && (
                  <p className="text-[#FF0000] sm:text-sm text-xs absolute  right-3">
                    Profile Image is mandatory *
                  </p>
                )}
                {imgloading.image1 ? (
                  <div className="flex">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 4335 4335"
                      width="20"
                      className="loading"
                      style={{ color: "#0ba934e0" }}
                    >
                      {" "}
                      <path
                        fill="#008DD2"
                        d="M3346 1077c41,0 75,34 75,75 0,41 -34,75 -75,75 -41,0 -75,-34 -75,-75 0,-41 34,-75 75,-75zm-1198 -824c193,0 349,156 349,349 0,193 -156,349 -349,349 -193,0 -349,-156 -349,-349 0,-193 156,-349 349,-349zm-1116 546c151,0 274,123 274,274 0,151 -123,274 -274,274 -151,0 -274,-123 -274,-274 0,-151 123,-274 274,-274zm-500 1189c134,0 243,109 243,243 0,134 -109,243 -243,243 -134,0 -243,-109 -243,-243 0,-134 109,-243 243,-243zm500 1223c121,0 218,98 218,218 0,121 -98,218 -218,218 -121,0 -218,-98 -218,-218 0,-121 98,-218 218,-218zm1116 434c110,0 200,89 200,200 0,110 -89,200 -200,200 -110,0 -200,-89 -200,-200 0,-110 89,-200 200,-200zm1145 -434c81,0 147,66 147,147 0,81 -66,147 -147,147 -81,0 -147,-66 -147,-147 0,-81 66,-147 147,-147zm459 -1098c65,0 119,53 119,119 0,65 -53,119 -119,119 -65,0 -119,-53 -119,-119 0,-65 53,-119 119,-119z"
                      />
                    </svg>
                    <span style={{ color: "#0ba934e0" }}>Uploading.....</span>
                  </div>
                ) : null}
              </label>
              <input
                  className="w-full border border-gray-300 p-2 rounded-md focus:outline-none focus:border-blue-500"
                  type="file"
                  placeholder="profile image"
                  name="hero_img"
                  ref={heroFileInputRef}
                  onChange={(e) => handleProfileFileChange("hero_img", e)}
                  disabled={imgloading.image2 ? true : false}
                  id="hero_img"
              />
            {formData.hero_img &&
            (<>
              <div className="relative">
                <img src={formData.hero_img} alt="" className="h-20 w-20"/>
                <button
                  onClick={handleCancleProfileCrop}
                  className="absolute top-0 right-0 text-lg font-semibold border border-1 px-1 rounded-lg hover:bg-slate-200"
                >
                  <CloseIcon fontSize="small"/>
                </button>
              </div>
            </>)}
            </div>

            <div className="mb-4 relative">
              <label
                className="text-gray-700 text-sm font-bold mb-2 flex justify-start"
                htmlFor="coverimage"
              >
                Cover Image <span className="text-danger">*</span>
                {!formData.cover_img && (
                  <p className="text-[#FF0000] sm:text-sm text-xs absolute right-3">
                    Cover Image is mandatory *
                  </p>
                )}
                {imgloading.image2 ? (
                  <div className="flex">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 4335 4335"
                      width="20"
                      className="loading"
                      style={{ color: "#0ba934e0" }}
                    >
                      {" "}
                      <path
                        fill="#008DD2"
                        d="M3346 1077c41,0 75,34 75,75 0,41 -34,75 -75,75 -41,0 -75,-34 -75,-75 0,-41 34,-75 75,-75zm-1198 -824c193,0 349,156 349,349 0,193 -156,349 -349,349 -193,0 -349,-156 -349,-349 0,-193 156,-349 349,-349zm-1116 546c151,0 274,123 274,274 0,151 -123,274 -274,274 -151,0 -274,-123 -274,-274 0,-151 123,-274 274,-274zm-500 1189c134,0 243,109 243,243 0,134 -109,243 -243,243 -134,0 -243,-109 -243,-243 0,-134 109,-243 243,-243zm500 1223c121,0 218,98 218,218 0,121 -98,218 -218,218 -121,0 -218,-98 -218,-218 0,-121 98,-218 218,-218zm1116 434c110,0 200,89 200,200 0,110 -89,200 -200,200 -110,0 -200,-89 -200,-200 0,-110 89,-200 200,-200zm1145 -434c81,0 147,66 147,147 0,81 -66,147 -147,147 -81,0 -147,-66 -147,-147 0,-81 66,-147 147,-147zm459 -1098c65,0 119,53 119,119 0,65 -53,119 -119,119 -65,0 -119,-53 -119,-119 0,-65 53,-119 119,-119z"
                      />
                    </svg>
                    <span style={{ color: "#0ba934e0" }}>Uploading.....</span>
                  </div>
                ) : null}
              </label>
              <input
                className="w-full border border-gray-300 p-2 rounded-md focus:outline-none focus:border-blue-500"
                type="file"
                placeholder="cover image"
                name="cover_img"
                ref={coverFileInputRef}
                onChange={(e) => handleCoverFileChange("cover_img", e)}
                disabled={imgloading.image1 ? true : false}
                id="cover_img"
              />
              {formData.cover_img &&
                (<>
                  <div className="relative">
                    <img src={formData.cover_img} alt="" className="h-20 w-3/4 sm:w-1/2"/>
                    <button
                      onClick={handleCancleCoverCrop}
                      className="absolute top-0 right-0 text-lg font-semibold border border-1 px-1 rounded-lg hover:bg-slate-200"
                    >
                      <CloseIcon fontSize="small"/>
                    </button>
                  </div>
                </>)}
            </div>

            <div className="mb-4  relative">
              <div className="justify-between">
                <label
                  className="text-gray-700 text-sm font-bold mb-2 flex justify-start"
                  htmlFor="communityType"
                >
                  Community Type <span className="text-danger">*</span>
                  {error.communityType && (
                    <p className="text-[#FF0000] sm:text-sm text-xs">
                      Community Type is mandatory *
                    </p>
                  )}
                </label>
                <div className="flex justify-between lg:w-1/2 sm:w-4/6 w-3/6 mb-4">
                  <div className="flex items-baseline	">
                    <input
                      className="flex items-baseline	border border-gray-300 p-2 rounded-md focus:outline-none focus:border-blue-500"
                      type="radio"
                      name="communityType"
                      value="private"
                      checked={formData.communityType === "private"}
                      onChange={(e) => handleChange(e)}
                    />
                    <label className="mx-2">Private</label>
                  </div>
                  <div className="flex items-baseline	">
                    <input
                      className="flex items-baseline	border border-gray-300 p-2 rounded-md focus:outline-none focus:border-blue-500"
                      type="radio"
                      name="communityType"
                      value="public"
                      checked={formData.communityType === "public"}
                      onChange={(e) => handleChange(e)}
                    />
                    <label className="mx-2">Public</label>
                  </div>

                  <div className="flex items-baseline	">
                    <input
                      className="border border-gray-300 p-2 rounded-md focus:outline-none focus:border-blue-500"
                      type="radio"
                      name="communityType"
                      value="protected"
                      checked={formData.communityType === "protected"}
                      onChange={(e) => {
                        handleChange(e);
                        setIsProtected(!isProtected);
                      }}
                    />
                    <label className="mx-2">Protected</label>
                  </div>
                </div>

                {isProtected === true ? (
                  <div className="mb-4 relative">
                    <label
                      className="text-gray-700 text-sm font-bold mb-2 flex justify-start"
                      htmlFor="community"
                    >
                      Protected Password <span className="text-danger">*</span>
                      {protectedpassword === "" && (
                        <p className="text-[#FF0000] sm:text-sm text-xs absolute right-3 sm:top-0 bottom-[70px]">
                          Community Protected Password is mandatory *
                        </p>
                      )}
                    </label>
                    <input
                      className="w-full border border-gray-300 p-2 rounded-md focus:outline-none focus:border-blue-500"
                      type="password"
                      placeholder="password"
                      value={protectedpassword}
                      onChange={(e) => setProtectedPassword(e.target.value)}
                    />
                  </div>
                ) : null}
              </div>
            </div>
            <div className="mb-4 relative">
              <label
                className="text-gray-700 text-sm font-bold mb-2 flex justify-start "
                htmlFor="discloseauthorname"
              >
                Disclose Author Name
                
              </label>
              <div className="flex items-center">
                <input
                  className="w-4 h-4 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
                  type="checkbox"
                  name="discloseauthorname"
                  checked={formData.discloseauthorname}
                  onChange={(e) => handleChange(e)}
                  onBlur={validateForm}
                />
                <span className="pl-2">Show</span>
              </div>
            </div>


            {cid ? (
              <button
                className="bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 focus:outline-none focus:shadow-outline-blue"
                type="button"
                onClick={handleUpdateCommunitie}
              >
                Update
              </button>
            ) : (
              <button
                className="bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 focus:outline-none focus:shadow-outline-blue"
                type="button"
                onClick={handleSubmitCommunitie}
              >
                Submit
              </button>
            )}
          </div>
        </div>
      </div>

      {profileImgCrop && (
        <div className="fixed inset-0 overflow-y-auto shadow-2xl">
          <div className="flex items-center justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 transition-opacity">
                <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen"></span>
              <div className="inline-flex align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle w-full sm:w-[50%] sm:p-6 h-[600px]">
              <Cropper
                image={croppedProfileImg}
                crop={crop}
                zoom={zoom}
                rotation={rotation}
                aspect={1} 
                onCropChange={(crop) => setCrop(crop)} 
                onCropComplete={(croppedArea, croppedAreaPixels) => {
                  setCroppedAreaPixels(croppedAreaPixels);
                }}
                onZoomChange={setZoom}
                onRotationChange={setRotation}
              />
              <div className="absolute bottom-0 left-0 px-4 flex-col sm:flex-row sm:flex w-full items-center justify-between py-5">
                <div className="sm:flex flex-col w-full sm:w-3/4 px-4 sm:px-1">
                  <div className="flex w-full items-center justify-between my-4">
                    <span className="mr-2 w-[60px]">Zoom</span>
                    <div className="flex w-full justify-between">
                    <input
                      type="range"
                      min={1}
                      max={3}
                      step={0.1}
                      value={zoom}
                      onChange={(e) => setZoom(Number(e.target.value))}
                      className="w-[80%] cursor-pointer"
                      disabled={isUpload}
                    />
                    <span className=" w-12">{zoom.toFixed(1)}</span>
                    </div>
                  </div>
                  <div className="flex w-full items-center justify-between my-4">
                    <span className="mr-2 w-[60px]">Rotation</span>
                    <div className="flex w-full justify-between">
                    <input
                      type="range"
                      min={0}
                      max={360}
                      value={rotation}
                      onChange={(e) => setRotation(Number(e.target.value))}
                      className="w-[80%] cursor-pointer"
                      disabled={isUpload}
                    />
                    <span className="w-12">{rotation.toFixed(2)}°</span>
                    </div>
                  </div>
                </div>
                <div className="flex sm:flex-col flex-row-reverse items-center justify-between w-full px-4 sm:px-1 sm:w-1/4">
                  <button 
                  className="bg-blue-500 text-white px-2 py-[10px] rounded-md hover:bg-blue-600 mb-0 sm:mb-2 w-28 h-9 flex items-center justify-center"
                  onClick={showCroppedProfileImage}
                  disabled={isUpload} 
                  >
                  {isUpload ? <>
                    <svg
                      width="24px"
                      height="24px"
                      xmlns="http://www.w3.org/2000/svg"
                      xlink="http://www.w3.org/1999/xlink"
                      stroke="#ffffff"
                      viewBox="0 0 100 100"
                      preserveAspectRatio="xMidYMid"
                    >
                      <circle cx="75" cy="50" fill="#ffffff" r="6.39718"> <animate attributeName="r" values="4.8;4.8;8;4.8;4.8" times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite" begin="-0.875s" ></animate> </circle> <circle cx="67.678" cy="67.678" fill="#ffffff" r="4.8"> <animate attributeName="r" values="4.8;4.8;8;4.8;4.8" times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite" begin="-0.75s" ></animate> </circle> <circle cx="50" cy="75" fill="#ffffff" r="4.8"> <animate attributeName="r" values="4.8;4.8;8;4.8;4.8" times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite" begin="-0.625s" ></animate> </circle> <circle cx="32.322" cy="67.678" fill="#ffffff" r="4.8"> <animate attributeName="r" values="4.8;4.8;8;4.8;4.8" times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite" begin="-0.5s" ></animate> </circle> <circle cx="25" cy="50" fill="#ffffff" r="4.8"> <animate attributeName="r" values="4.8;4.8;8;4.8;4.8" times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite" begin="-0.375s" ></animate> </circle> <circle cx="32.322" cy="32.322" fill="#ffffff" r="4.80282" > <animate attributeName="r" values="4.8;4.8;8;4.8;4.8" times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite" begin="-0.25s" ></animate> </circle> <circle cx="50" cy="25" fill="#ffffff" r="6.40282"> <animate attributeName="r" values="4.8;4.8;8;4.8;4.8" times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite" begin="-0.125s" ></animate> </circle> <circle cx="67.678" cy="32.322" fill="#ffffff" r="7.99718" > <animate attributeName="r" values="4.8;4.8;8;4.8;4.8" times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite" begin="0s" ></animate> </circle>
                    </svg>{" "}
                    <span className="mt-[2px]">Loading</span></> : `Upload` }
                  </button>
                  <button 
                  className="flex items-center justify-center bg-gray-300 text-gray-700 px-2 py-[10px] w-28 h-9 rounded-md hover:bg-gray-400"
                  onClick={handleCancleProfileCrop}
                  disabled={isUpload} 
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {coverImgCrop && (
        <div className="fixed inset-0 overflow-y-auto shadow-2xl">
          <div className="flex items-center justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 transition-opacity">
                <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen"></span>
            <div className="inline-flex align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle w-full sm:w-[50%] sm:p-6 h-[600px]">
              <Cropper
                image={croppedCoverImg}
                crop={crop}
                zoom={zoom}
                rotation={rotation}
                aspect={287/80} 
                onCropChange={(crop) => setCrop(crop)} 
                onCropComplete={(croppedArea, croppedAreaPixels) => {
                  setCroppedAreaPixels(croppedAreaPixels);
                }}
                onZoomChange={setZoom}
                onRotationChange={setRotation}
              />
            <div className="absolute bottom-0 left-0 px-4 flex-col sm:flex-row sm:flex w-full items-center justify-between py-5">
              <div className="sm:flex flex-col w-full sm:w-3/4 px-4 sm:px-1">
                <div className="flex w-full items-center justify-between my-4">
                  <span className="mr-2 w-[60px]">Zoom</span>
                  <div className="flex w-full justify-between">
                  <input
                    type="range"
                    min={1}
                    max={3}
                    step={0.1}
                    value={zoom}
                    onChange={(e) => setZoom(Number(e.target.value))}
                    className="w-[80%] cursor-pointer"
                    disabled={isUpload}
                  />
                  <span className=" w-12">{zoom.toFixed(1)}</span>
                  </div>
                </div>
                <div className="flex w-full items-center justify-between my-4">
                  <span className="mr-2 w-[60px]">Rotation</span>
                  <div className="flex w-full justify-between">
                  <input
                    type="range"
                    min={0}
                    max={360}
                    value={rotation}
                    onChange={(e) => setRotation(Number(e.target.value))}
                    className="w-[80%] cursor-pointer"
                    disabled={isUpload}
                  />
                  <span className="w-12">{rotation.toFixed(2)}°</span>
                  </div>
                </div>
              </div>
              <div className="flex sm:flex-col flex-row-reverse items-center justify-between w-full px-4 sm:px-1 sm:w-1/4">
                <button 
                className="bg-blue-500 text-white px-2 py-[10px] rounded-md hover:bg-blue-600 mb-0 sm:mb-2 w-28 h-9 flex items-center justify-center"
                onClick={showCroppedCoverImage}
                disabled={isUpload} 
                >
                {isUpload ? <>
                  <svg
                    width="24px"
                    height="24px"
                    xmlns="http://www.w3.org/2000/svg"
                    xlink="http://www.w3.org/1999/xlink"
                    stroke="#ffffff"
                    viewBox="0 0 100 100"
                    preserveAspectRatio="xMidYMid"
                  >
                    <circle cx="75" cy="50" fill="#ffffff" r="6.39718"> <animate attributeName="r" values="4.8;4.8;8;4.8;4.8" times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite" begin="-0.875s" ></animate> </circle> <circle cx="67.678" cy="67.678" fill="#ffffff" r="4.8"> <animate attributeName="r" values="4.8;4.8;8;4.8;4.8" times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite" begin="-0.75s" ></animate> </circle> <circle cx="50" cy="75" fill="#ffffff" r="4.8"> <animate attributeName="r" values="4.8;4.8;8;4.8;4.8" times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite" begin="-0.625s" ></animate> </circle> <circle cx="32.322" cy="67.678" fill="#ffffff" r="4.8"> <animate attributeName="r" values="4.8;4.8;8;4.8;4.8" times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite" begin="-0.5s" ></animate> </circle> <circle cx="25" cy="50" fill="#ffffff" r="4.8"> <animate attributeName="r" values="4.8;4.8;8;4.8;4.8" times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite" begin="-0.375s" ></animate> </circle> <circle cx="32.322" cy="32.322" fill="#ffffff" r="4.80282" > <animate attributeName="r" values="4.8;4.8;8;4.8;4.8" times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite" begin="-0.25s" ></animate> </circle> <circle cx="50" cy="25" fill="#ffffff" r="6.40282"> <animate attributeName="r" values="4.8;4.8;8;4.8;4.8" times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite" begin="-0.125s" ></animate> </circle> <circle cx="67.678" cy="32.322" fill="#ffffff" r="7.99718" > <animate attributeName="r" values="4.8;4.8;8;4.8;4.8" times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite" begin="0s" ></animate> </circle>
                  </svg>{" "}
                  <span className="mt-[2px]">Loading</span></> : `Upload` }
                </button>
                <button 
                className="flex items-center justify-center bg-gray-300 text-gray-700 px-2 py-[10px] w-28 h-9 rounded-md hover:bg-gray-400"
                onClick={handleCancleCoverCrop}
                disabled={isUpload} 
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CreateCommunities;
