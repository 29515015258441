import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { FaSearch } from "react-icons/fa";
import Layout from "../components/layout";
import TopBanner from "../components/topBanner";
import { CiEdit, CiBookmarkRemove } from "react-icons/ci";
import { useUser } from "../contexts/UserProvider";
import { getCookie } from "../config/coockies";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { apiurl, marketplaceapiurl } from "../config/config";
import { MdVerified } from "react-icons/md";
import { validateToken } from "../config/validateToken";
import { toast } from "react-toastify";
import { socket } from "../config/socket";
const MySwal = withReactContent(Swal);

const Communities = () => {
  const token = getCookie("savage_token");
  const heroImage = `https://community.savageseller.com/assets/hero-img.png`;
  const { userData } = useUser();
  const [loading, setLoading] = useState(false);
  const [communities, setCommunities] = useState([]);
  const [searchCommunities, setSearchCommunities] = useState("");
  const [verifiedUser, setVerifiedUser] = useState(null);
  const [vendorStatus, setVendorStatus] = useState({});
  const [showProfilePrompt, setShowProfilePrompt] = useState(false);
  
  const navigate = useNavigate();
  
  useEffect(() => {
    // const promptShown = localStorage.getItem("profilePromptShown");
    // if (promptShown === "false") {
      setShowProfilePrompt(true); 
      localStorage.setItem("profilePromptShown", "true");
    // }
  }, []);

  const fetchCommunities = () => {
    setLoading(true);
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${apiurl}/community/get`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    };

    axios
      .request(config)
      .then((response) => {
        setCommunities(response.data||[]);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchCommunities();
  }, []);

  const handleSearchCommunities = async () => {
    if (searchCommunities.length > 0) {
      try {
        const response = await axios.get(
          `${apiurl}/community/searchcommunity?by=${searchCommunities}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: token,
            },
          }
        );
        setCommunities(response.data.data||[]);
        setSearchData(response.data.data?true:false)
      } catch (error) {
        console.error("Error searching communities:", error);
      }
    } else {
      fetchCommunities();
    }
  };

  // console.log("Communities",communities);

  const deleteCommunityPop = (id) => {
    MySwal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        await deleteCommunity(id);
        MySwal.fire({
          title: "Deleted!",
          text: "Your Community has been deleted.",
          icon: "success",
        });
      }
    });
  };

  const deleteCommunity = async (id) => {
    try {
      const response = await axios.delete(
        `${apiurl}/community/delete-community/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      fetchCommunities();
    } catch (error) {
      console.error("Error searching communities:", error);
    }
  };
  
  const checkVendor = async (email) => {
    try {
      const { data } = await axios.post(
        `${marketplaceapiurl}/seller/checksellerexistance`,
        { email }
      );
      return data?.data?.sellerType === "organization";
    } catch (error) {
      console.error("Error checking vendor status:", error);
      return false;
    }
  };
  useEffect(() => {
    const fetchVendorStatus = async () => {
      const statuses = {};
      for (const community of communities) {
        const isVendor = await checkVendor(community?.author?.useremail);
        statuses[community._id] = isVendor;
      }
      setVendorStatus(statuses);
    };

    if (communities?.length > 0) {
      fetchVendorStatus();
    }
  }, [communities]);

  const isCurrentUser = (community) => {
    return verifiedUser && community.author?._id === verifiedUser?._id;
  };
  const handleJoinCommunity = async (id) => {
    //setLoading(true);
    try {
      const response = await axios.post(
        `${apiurl}/community/send-join-request`,
        {
          userId: verifiedUser?._id,
          communityId: id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      if (response?.data?.success === true) {
        //setLoading(false);
        fetchCommunities();
        toast.success(response?.data?.message);
      } else {
        //setLoading(false);
        toast.error(response?.data?.message);
      }
    } catch (error) {
      //setLoading(false);
      console.error("Join Community error", error);
    }
  };

  const handleJoinCommunityNotification = async (community) => {
    setLoading(true);
    const data = {
        userId: {
            _id: verifiedUser?._id,
        },
        type: (community?.communityType === "private" || community?.communityType === "protected") ? "JOIN_COMMUNITY_REQUEST" : "JOIN_COMMUNITY",
        message: (community?.communityType === "private" || community?.communityType === "protected") ? `requested to join the community` : "joined the community!" ,
        community: {
            _id: community?._id
        },
        post: {
            _id: ""
              },
        author: {
            _id: community?.author?._id,
        }
    }
    try {
      const response = await axios.post(
        `${apiurl}/notifications/createNotification`, data
        ,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      if (response?.data?.success === true) {
        setLoading(false);
        fetchCommunities();
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.error("Join Community Notification error", error);
    }
  };
 
  useEffect(() => {
    const token = getCookie("savage_token");
    validateToken(token)
      .then((user) => {
        if (user?.success === true) {
          setVerifiedUser(user.user);
          socket.emit("join", user.user._id);
        }
      })
      .catch((error) => {
        console.error("Token validation failed:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const maxAboutLength = Math.max(...communities.map(item => item.about.length));
  const dynamicHeight = maxAboutLength > 78 ? "sm:h-[100px]" : maxAboutLength > 38 ? "sm:h-24" : "";

  const [searchData, setSearchData] = useState(false);
  return (
    <Layout>
      <div className="bg-[#F9F9F9] p-5">
        <TopBanner text="Communities" />
        {loading === true ? (
          <div className="w-3/4 m-auto p-5">
            <div className="grid grid-cols-3 gap-4">
              <div className="bg-gray-200 rounded p-4 animate-pulse p-20"></div>
              <div className="bg-gray-200 rounded p-4 animate-pulse p-20"></div>
              <div className="bg-gray-200 rounded p-4 animate-pulse p-20"></div>

              {/* Second Row */}
              <div className="bg-gray-200 rounded p-4 animate-pulse p-20"></div>
              <div className="bg-gray-200 rounded p-4 animate-pulse p-20"></div>
              <div className="bg-gray-200 rounded p-4 animate-pulse p-20"></div>
            </div>
          </div>
        ) : (
          <div className="pt-5">
          
            <div className="sm:flex block">
              <div className=" flex bg-[#fff] lg:w-1/3 w-full m-auto rounded-md shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px]">
                <button className="px-4 focus:outline-none">
                  <FaSearch className="h-5 w-5 text-[#000]" />
                </button>
                <input
                  type="text"
                  className="py-2 lg:w-[85%] w-[80%]  focus:outline-none"
                  value={searchCommunities}
                  placeholder="Search Communities"
                  onChange={(e) => setSearchCommunities(e.target.value)}
                  onKeyUp={handleSearchCommunities}
                />
                
              </div>
              <div className="flex items-center justify-center pt-5 sm:pt-0">
                <div
                  className="bg-blue-800 hover:bg-blue-600 text-white text-center py-1 px-3 rounded-md transition duration-300  font-semibold text-sm lg:text-lg border border-[#fff] cursor-pointer"
                  onClick={() => navigate("/create-community")}
                >
                  Create a Community
                </div>
                <div
                  className="ml-3 sm:ml-5 bg-blue-800 hover:bg-blue-600 text-white text-center py-1 px-3 rounded-md transition duration-300  font-semibold text-sm lg:text-lg border border-[#fff] cursor-pointer"
                  onClick={() => navigate("/profilecommunity")}
                >
                  Your communities
                </div>
              </div>
            </div>

            <div className="lg:grid block grid-cols-4 gap-4 w-full m-auto my-5 pb-8 relative">
            {searchData && searchCommunities.length > 0?
            <div className="absolute top-0 sm:left-5 z-50  sm:w-[550px] lg:w-[750px] w-full shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px] max-h-screen overflow-y-scroll border-0">
                                      <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                        <div className="flex justify-end mr-2 p-3 border-b border-solid border-gray-300 rounded-t ">
                                          <button
                                            className="bg-transparent border-0 text-black text-3xl"
                                           onClick={() => setSearchData(false)}
                                          >
                                            {/* <p>{searchData.length >0 ? 'NotFound Data' : null}</p> */}

                                            <span>x</span>
                                          </button>
                                        </div>
                                        <div className="px-4 py-2">
                                          <h3 className="text-xl font=semibold mb-2 w-full">
                                            {communities?.length > 0
                                              ? "Communities"
                                              : null}
                                          </h3>
                                          {communities?.map(
                                            (community, index) => (
                                              <div className="mb-2 w-[48%] inline-block mx-[1%]">
                                                <div
                                                  className="py-2 px-1 flex flex-col cursor-pointer bg-[#fff] rounded-lg shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px] lg:mb-0 sm:h-full hover:bg-gray-300 transition-all duration-400"
                                                  key={index}
                                                >
                                                  <div className="flex justify-between items-center">
                                                    <Link
                                                      to={`/community/${community.community_handle}`}
                                                      //target="blank"
                                                    >
                                                      <div className="flex justify-between items-center">
                                                        <img
                                                          src={community?.hero_img?community?.hero_img:"/assets/girl.png"}
                                                          alt="savage-logo"
                                                          className="w-12 h-12  rounded-full border-blue border-[5px] cursor-pointer"
                                                        />
                                                        <div className="lg:w-[80%] pl-2">
                                                          <h2 className=" cursor-pointer ">
                                                            {community?.title}
                                                          </h2>
                                                        </div>
                                                      </div>
                                                    </Link>
                                                  </div>
                                                </div>
                                              </div>
                                            )
                                          )}
                                         </div>
                                      </div>
                                    </div>:""}

              {communities &&
                communities.map((item, index) => {
                  const isUser = isCurrentUser(item);
                  const isVendor = vendorStatus[item._id] || false;
                  const approvedMembers = item?.members.filter( (member) => member.status === "approved" );
                  const memberCount = approvedMembers.length;
                  const isMember = item.members?.some(
                   (member) => member._id === verifiedUser?._id && member.status === "approved"
                   );
                   const requestMember = item.members?.some(
                   (member) => member._id === verifiedUser?._id && member.status === "pending"
                   );
                  return (
                    <div
                      className="p-4 flex flex-col justify-between bg-[#fff] rounded-md shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px] lg:mb-0 mb-5 sm:h-full"
                      key={index}
                    >
                      <Link to={`/community/${item.community_handle}`}>
                        <div className={`*:first-letter:flex justify-between mb-2 items-center bg-cover ${item?.cover_img ? 'border' : 'border-none'} border-[#eee] bg-center bg-no-repeat rounded-md h-[80px]`} style={{ backgroundImage: `url(${item?.cover_img ? item?.cover_img : '/assets/blue-bg.png'})` }}></div>
                        <div className="flex justify-between mb-2 items-center">
                          <img
                            src={item.hero_img}
                            alt="profile-image"
                            className="w-[60px] h-[60px]  rounded-full border-blue border-[5px] cursor-pointer mt-[-50px] ml-5"
                          />
                          <div className="flex flex-col">
                          {item.communityType === "public" ? (
                            <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-600 ring-1 ring-inset ring-green-500/10">
                              Public
                            </span>
                          ) : item.communityType === "private" ? (
                            <span className="inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-600 ring-1 ring-inset ring-red-500/10">
                              Private
                            </span>
                          ) : item.communityType === "protected" ? (
                            <span className="inline-flex items-center rounded-md bg-blue-50 px-2 py-1 text-xs font-medium text-blue-600 ring-1 ring-inset ring-blue-500/10">
                              Protected
                            </span>
                          ) : null}
                          </div>
                        </div>

                        <div className={`lg:w-full relative pl-2 border-b border-[#f0f0f0] pb-5 ${dynamicHeight}`}>
                          <h2 className="flex items-center text-[16px] lg:text-[18px] font-bold  cursor-pointer">
                            {isVendor ?<MdVerified className="text-blue-600 mr-2"/>:""}
                            {item.title.length > 28 ? `${item.title.substring(0, 27)}...` : item.title}
                          </h2>
                          <p className="font-[400] text-[14px] text-[#000] line-clamp-3">
                            {item.about}
                          </p>
                        </div>
                      </Link>
                      {!isMember && !isUser && !requestMember?
                      <button className="bg-[#1e40af] text-[#fff] rounded-md mt-2 px-2 py-1 text-xs "  onClick={()=>{handleJoinCommunity(item._id);handleJoinCommunityNotification(item)}}>Join Now</button>:""}
                      {requestMember && !isUser?
                        <button className="bg-[#1e40af] text-[#fff] rounded-md mt-2 px-2 py-1 text-xs " >Requested</button>
                      :""}

                      <div className={`lg:w-full relative pl-2 ${item?.discloseauthorname === true ? 'justify-between mt-4' : 'justify-end mt-3'} items-center flex ${memberCount > 0 ? 'pt-2' : 'pt-1'}`}>
                        <p className={`text-[rgb(0,0,0)] flex absolute py-2 left-2 ${item?.discloseauthorname === true ? '-top-3' : ''}`}>
                          {isUser === true && (
                            <>
                              <CiEdit
                                onClick={() =>
                                  navigate(
                                    `/create-community?handle=${item.community_handle}&cid=${item._id}`
                                  )
                                }
                                className="text-[#fff] bg-[#038703] border mr-1 border-gray-500 rounded-full p-1.5 h-8 w-8 hover:bg-blue-700 transition-all duration-300 hover:text-white cursor-pointer"
                              />
                              <CiBookmarkRemove
                                onClick={() => deleteCommunityPop(item._id)}
                                className="text-[#fff] bg-[#ff0000]  rounded-full p-1.5 h-8 w-8 hover:bg-blue-700 transition-all duration-300 hover:text-white cursor-pointer"
                              />
                            </>
                          )}
                        </p>
                        
                        {item?.discloseauthorname && (
                          <span className="text-sm capitalize my-1 font-semibold mt-5 pt-3">
                            {item?.author?.username}
                          </span>
                        )}

                        <div className="flex items-center">
                          {memberCount === 0 ? (
                            <span className="text-xs mt-5 pt-2">Be the first to join</span>
                          ) : (
                            <div className="flex flex-col items-center justify-center">
                              <div className="flex items-center">
                                {approvedMembers.slice(0, 3).map((member, idx) => (
                                  <img
                                    key={member._id}
                                    src={member.hero_img || (idx === 0 ? '/assets/member1.jpg' : idx === 1 ? '/assets/member2.jpg' : '/assets/member3.jpg')}
                                    alt={`${member.username}'s profile`}
                                    className={`w-8 h-8 rounded-full border-[#f0f0f0] border-[2px] ${idx > 0 ? 'ml-[-10px]' : ''}`}
                                  />
                                ))}
                              </div>
                              <span className="mx-2 text-xs mt-1">
                                {memberCount} {memberCount === 1 ? "Member" : "Members"}
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        )}
      </div>
      {(showProfilePrompt && (verifiedUser?.hero_img === heroImage)) && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50 h-full overflow-y-scroll">
            <div className="w-full sm:w-1/2 p-2 absolute top-0">
              <div className={`bg-white p-6 py-5 rounded shadow border border-blue-600 w-full m-auto my-2 update_Profile ease-in-out duration-700 transition-all slideBottom`}>
                <div className="flex justify-center relative">
                  <h2 className="text-center text-xl sm:text-2xl font-semibold mb-4 text-gray-700">
                    Complete your picture
                  </h2>
                  <button
                    onClick={() => setShowProfilePrompt(false)}
                    className="text-xl font-semibold border border-1 px-2 rounded-lg py-0 text-red-500 hover:bg-slate-200 absolute -right-4 -top-3"
                  >
                    X
                  </button>
                </div>
                <div className="w-full m-auto flex flex-col items-center justify-center">
                  <div className="flex items-center justify-center text-slate-700 text-sm sm:text-lg text-center">
                    Please add you profile picture to complete your profile!
                  </div>
                  <button onClick={() => navigate("/profile")} className="text-center text-xl border bg-blue-600 py-1 px-4 rounded-lg text-white m-3 mb-0">Ok</button>
                </div>
              </div>
            </div>
        </div>
      )}
    </Layout>
  );
};

export default Communities;
