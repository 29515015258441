import React, { Fragment, useContext } from "react";
import { NewGroupCallContext } from "../contexts/NewGroupCall";
import { BsMic, BsMicMute } from "react-icons/bs";
import { HiOutlineVideoCamera } from "react-icons/hi";
import { MdOutlineVideocamOff } from "react-icons/md";
import { SlCallEnd } from "react-icons/sl";

export default function IncomingVideoCam() {
  const {
    isGroupCalling,
    MyGroupVideoRef,
    allRefs,
    LocalVideoStatus,
    LocalMicStatus,
    leaveCall,
    toogleMic,
    toogleVideo,
    Calls
  } = useContext(NewGroupCallContext);

  return (
    <Fragment>
      {isGroupCalling ? (
        <div className="fixed top-0 left-0 right-0 bottom-0 bg-[#00000061] overflow-auto">
          <div className="video_box h-full z-[99]">
            <div className="flex absolute md:w-[20%] w-[60%] bottom-[20px] left-[25px] rounded-[10px] z-[9] flex-wrap gap-[10px]">
              <video
                ref={MyGroupVideoRef}
                autoPlay
                className="mirror w-full rounded border-[3px] border-[white] bg-[#000] min-h-[250px]"
              />
              <div className="flex flex-wrap justify-between w-full space-x-5 items-center">
                <button
                  onClick={() => {
                    toogleMic();
                  }}
                  className="mic_btn px-4 py-2 rounded-md focus:border focus:border-indigo-700 outline-none bg-indigo-700 focus:text-white focus:bg-transparent"
                >
                  {LocalMicStatus ? (
                    <BsMic className="text-white text-2xl off" />
                  ) : (
                    <BsMicMute className="text-white text-2xl on" />
                  )}
                </button>
                {Calls.calltype === "video" ? (
                  <button
                    type="button"
                    onClick={() => toogleVideo()}
                    className="video_btn px-4 py-2 rounded-md focus:border focus:border-sky-700 outline-none bg-sky-700 focus:text-white focus:bg-transparent"
                  >
                    {LocalVideoStatus ? (
                      <HiOutlineVideoCamera className="text-white text-2xl" />
                    ) : (
                      <MdOutlineVideocamOff className="text-white text-2xl" />
                    )}
                  </button>
                ) : null}
                <button
                  onClick={() => leaveCall()}
                  type="button"
                  className="cancel_call_btn  px-4 py-2 rounded-md focus:border focus:border-red-700 outline-none bg-red-700 focus:text-white focus:bg-transparent"
                >
                  <SlCallEnd className="text-white text-2xl" />
                </button>
              </div>
            </div>
            <div className="flex flex-wrap items-center justify-between">
              {Object.values(allRefs).length !== 0
                ? Object.keys(allRefs).map((partner, key) => {
                    return (
                      <div
                        className={`${
                          Object.values(allRefs).length === 1
                            ? "w-[100%]"
                            : Object.values(allRefs).length === 2
                            ? "w-[50%]"
                            : "w-[33.33%]"
                        } p-[10px]`}
                        key={key}
                      >
                        <video
                          ref={(el) => {
                            if (el && allRefs[partner]) {
                              el.srcObject = allRefs[partner]; // Attach the stream to the video element
                            }
                          }}
                          id={key}
                          autoPlay
                          playsInline
                          className={`${Object.values(allRefs).length === 1 ? "" : "w-full "}rounded-[10px] mirror border-[3px] border-[white] min-h-[250px] bg-[#000]`}
                        />
                      </div>
                    );
                  })
                : null}
            </div>
          </div>
        </div>
      ) : null}
    </Fragment>
  );
}
