import React, { useEffect, useState } from "react";
import axios from "axios";
import Layout from "../components/layout";
import { Link, useNavigate } from "react-router-dom";
import { apiurl, marketplaceapiurl } from "../config/config";
import { useUser } from "../contexts/UserProvider";
import TopBanner from "../components/topBanner";
import { getCookie } from "../config/coockies";
import LoaderSpiner from "../components/loader";
import { MdVerified } from "react-icons/md";
import { CiEdit, CiBookmarkRemove } from "react-icons/ci";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { validateToken } from "../config/validateToken";

const MySwal = withReactContent(Swal);

const Home = () => {
  const blogdata = [
    {
      title: "Classy Marketplace",
      content:
        "Keep in mind the potential security implications and consider carefully whether this is appropriate for your use case. If you do choose to allow requests from all origins, make sure to implement other security measures to protect your server and its data.",
      imageUrl: "/assets/group-img.jpg",
    },
    {
      title: "Better Store",
      content:
        "Keep in mind the potential security implications and consider carefully whether this is appropriate for your use case. If you do choose to allow requests from all origins, make sure to implement other security measures to protect your server and its data.",
      imageUrl: "/assets/group-img.jpg",
    },
    {
      title: "Social Platform",
      content:
        "Keep in mind the potential security implications and consider carefully whether this is appropriate for your use case. If you do choose to allow requests from all origins, make sure to implement other security measures to protect your server and its data.",
      imageUrl: "/assets/group-img.jpg",
    },
  ];

  const heroImage = `https://community.savageseller.com/assets/hero-img.png`
  const { userData } = useUser();
  const navigate = useNavigate();
  const [blog, setblog] = useState(blogdata);
  const [communities, setCommunities] = useState([]);
  const token = getCookie("savage_token");
  const [loading, setLoading] = useState(false);
  const [verifiedUser, setVerifiedUser] = useState(null);
  const [featuredCommunity, setFeaturedCommunity] = useState([]);

  const [showProfilePrompt, setShowProfilePrompt] = useState(false);

  useEffect(() => {
    const userHasProfilePic = userData?.hero_img !== heroImage ;
    if (!userHasProfilePic) {
      setShowProfilePrompt(true);
    }
  }, []);

  const fetchCommunities = () => {
    setLoading(true);
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${apiurl}/community/get`,
      headers: {
        "Content-Type": "application/json",
      },
    };
    axios
      .request(config)
      .then((response) => {
        setLoading(false);
        setCommunities(response.data);
        const featuredOnly = response.data.filter((item) => item.communityType === "public");
        setFeaturedCommunity(featuredOnly);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };
  useEffect(() => {
    fetchCommunities();
  }, []);

  const [vendorStatus, setVendorStatus] = useState({});
  const checkVendor = async (email) => {
    try {
      const { data } = await axios.post(
        `${marketplaceapiurl}/seller/checksellerexistance`,
        { email }
      );
      return data?.data?.sellerType === "organization";
    } catch (error) {
      console.error("Error checking vendor status:", error);
      return false;
    }
  };

  useEffect(() => {
    const fetchVendorStatus = async () => {
      const statuses = {};
      for (const community of communities) {
        const isVendor = await checkVendor(community?.author?.useremail);
        statuses[community._id] = isVendor;
      }
      setVendorStatus(statuses);
    };

    if (communities?.length > 0) {
      fetchVendorStatus();
    }
  }, [communities]);

  const deleteCommunityPop = (id) => {
    MySwal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        await deleteCommunity(id);
        MySwal.fire({
          title: "Deleted!",
          text: "Your Community has been deleted.",
          icon: "success",
        });
      }
    });
  };

  const deleteCommunity = async (id) => {
    try {
      const response = await axios.delete(
        `${apiurl}/community/delete-community/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      fetchCommunities();
    } catch (error) {
      console.error("Error searching communities:", error);
    }
  };

  useEffect(() => {
    const token = getCookie("savage_token");
    validateToken(token)
    .then((user) => {
        if (user?.success === true) {
          setVerifiedUser(user.user);
        }
      })
      .catch((error) => {
        console.error("Token validation failed:", error);
      })
      .finally(() => {
        setLoading(false);
      });
    }, []);
    
    const isCurrentUser = (community) => {
      return verifiedUser && community.author?._id === verifiedUser?._id;
    };

    const maxAboutLength = Math.max(...featuredCommunity.map(item => item.about.length));
    const dynamicHeight = maxAboutLength > 78 ? "sm:h-[100px]" : maxAboutLength > 38 ? "sm:h-24" : "";

    return (
    <>
      {loading === true ? <LoaderSpiner text="Loading ..." /> : null}
      <Layout>
        <TopBanner name="homepage" />
        {featuredCommunity.length > 0 && (
          <section className="bg-gray-100 sm:py-8 pt-4 sm:pb-4">
            <div className="container mx-auto">
              <h2 className={`text-2xl font-semibold mb-4 px-5 ${featuredCommunity.slice(0, 4).length > 2 ? 'text-center' : 'lg:text-left text-center'}`}>
                Featured Communities
              </h2>
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 px-5">
                {featuredCommunity &&
                  featuredCommunity.slice(0, 4)
                    .map((item, index) => {
                      const isUser = isCurrentUser(item);
                      const isVendor = vendorStatus[item._id] || false;
                      const approvedMembers = item?.members.filter( (member) => member.status === "approved" );
                      const memberCount = approvedMembers.length;
                      return (
                        <div
                      className="p-4 flex flex-col justify-between bg-[#fff] rounded-md shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px] lg:mb-0 mb-5 sm:h-full"
                      key={index}
                    >
                      <Link to={`/community/${item.community_handle}`}>
                        <div className={`*:first-letter:flex justify-between mb-2 items-center bg-cover ${item?.cover_img ? 'border' : 'border-none'} border-[#eee] bg-center bg-no-repeat rounded-md h-[80px]`} style={{ backgroundImage: `url(${item?.cover_img ? item?.cover_img : '/assets/blue-bg.png'})` }}></div>
                        <div className="flex justify-between mb-2 items-center py-2 ">
                          <img
                            src={item.hero_img}
                            alt="profile-image"
                            className="w-[60px] h-[60px]  rounded-full border-blue border-[5px] cursor-pointer mt-[-50px] ml-5"
                          />
                          {/* {item.communityType === "public" ? (
                            <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-600 ring-1 ring-inset ring-green-500/10">
                              Public
                            </span>
                          ) : item.communityType === "private" ? (
                            <span className="inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-600 ring-1 ring-inset ring-red-500/10">
                              Private
                            </span>
                          ) : item.communityType === "protected" ? (
                            <span className="inline-flex items-center rounded-md bg-blue-50 px-2 py-1 text-xs font-medium text-blue-600 ring-1 ring-inset ring-blue-500/10">
                              Protected
                            </span>
                          ) : null} */}
                        </div>

                        <div className={`lg:w-full relative pl-2 border-b border-[#f0f0f0] pb-5 ${dynamicHeight}`}>
                          <h2 className="flex items-center text-[16px] lg:text-[18px] font-bold  cursor-pointer">
                            {isVendor ?<MdVerified className="text-blue-600 mr-2"/>:""}
                            {item.title.length > 28 ? `${item.title.substring(0, 27)}...` : item.title}
                          </h2>
                          <p className="font-[400] text-[14px] text-[#000] line-clamp-3">
                            {item.about}
                          </p>
                        </div>
                      </Link>
                      <div className={`lg:w-full relative pl-2 ${item?.discloseauthorname === true ? 'justify-between mt-4' : 'justify-end mt-3'}  items-center flex ${memberCount > 0 ? 'pt-2' : 'pt-5'}`}>
                        <p className={`text-[rgb(0,0,0)] flex absolute py-2 left-2 ${item?.discloseauthorname === true ? '-top-3' : ''}`}>
                          {isUser === true && (
                            <>
                              <CiEdit
                                onClick={() =>
                                  navigate(
                                    `/create-community?handle=${item.community_handle}&cid=${item._id}`
                                  )
                                }
                                className="text-[#fff] bg-[#038703] border mr-1 border-gray-500 rounded-full p-1.5 h-8 w-8 hover:bg-blue-700 transition-all duration-300 hover:text-white cursor-pointer"
                              />
                              <CiBookmarkRemove
                                onClick={() => deleteCommunityPop(item._id)}
                                className="text-[#fff] bg-[#ff0000]  rounded-full p-1.5 h-8 w-8 hover:bg-blue-700 transition-all duration-300 hover:text-white cursor-pointer"
                              />
                            </>
                          )}
                        </p>
                        {item?.discloseauthorname && (
                          <span className={`text-sm capitalize my-1 font-semibold mt-5 ${memberCount > 0 ? 'pt-3' : ''}`}>
                            {item?.author?.username}
                          </span>
                        )}
                        <div className="flex items-center">
                          {memberCount === 0 ? (
                            <span className="text-xs mt-4">Be the first to join</span>
                          ) : (
                            <div className="flex flex-col items-center justify-center">
                              <div className="flex items-center ">
                              {approvedMembers.slice(0, 3).map((member, idx) => (
                                  <img
                                    key={member._id}
                                    src={member.hero_img || (idx === 0 ? '/assets/member1.jpg' : idx === 1 ? '/assets/member2.jpg' : '/assets/member3.jpg')}
                                    alt={`${member.username}'s profile`}
                                    className={`w-8 h-8 rounded-full border-[#f0f0f0] border-[2px] ${idx > 0 ? 'ml-[-10px]' : ''}`}
                                  />
                                ))}
                              </div>
                              <span className="mx-2 text-xs mt-1">
                                {memberCount} {memberCount === 1 ? "Member" : "Members"}
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                      );
                    })}
              </div>
            </div>
          </section>
        )}
        <section className="bg-gray-100 sm:py-8 pb-8 pt-4 text-center">
          <div className="container mx-auto">
            <h2 className="text-2xl font-semibold mb-4">Community Articles</h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-4 px-5">
              {blog.map((item, index) => {
                return (
                  <div
                    className="bg-white p-4 rounded-md shadow-md"
                    key={index}
                  >
                    <img
                      src="/assets/blog-1.jpg"
                      alt="Community-Image"
                      className="w-full h-[200px] object-cover mb-4 rounded-md"
                    />
                    <h3 className="text-xl font-semibold mb-2 capitalize">
                      {item.title}
                    </h3>
                    <p className="text-gray-600"></p>Keep in mind the potential
                    security implications and consider carefully whether this is
                    appropriate for your use case. If you do choose to allow
                    requests from all origins, make sure to implement other
                    security measures to protect your server and its data.
                  </div>
                );
              })}
            </div>
          </div>
        </section>
        {(showProfilePrompt) && (
          <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50 h-full overflow-y-scroll">
            <div className="w-full sm:w-1/2 p-2 absolute top-0">
              <div className={`bg-white p-6 py-5 rounded shadow border border-blue-600 w-full m-auto my-2 update_Profile ease-in-out duration-700 transition-all slideBottom`}>
                <div className="flex justify-center relative">
                  <h2 className="text-center text-xl sm:text-2xl font-semibold mb-4 text-gray-700">
                    Complete your picture
                  </h2>
                  <button
                    onClick={() => setShowProfilePrompt(false)}
                    className="text-xl font-semibold border border-1 px-2 rounded-lg py-0 text-red-500 hover:bg-slate-200 absolute -right-4 -top-3"
                  >
                    X
                  </button>
                </div>
                <div className="w-full m-auto flex flex-col items-center justify-center">
                  <div className="flex items-center justify-center text-slate-700 text-sm sm:text-lg text-center">
                    Please add you profile picture to complete your profile!
                  </div>
                  <button onClick={() => navigate("/profile")} className="text-center text-xl border bg-blue-600 py-1 px-4 rounded-lg text-white m-3 mb-0">Ok</button>
                </div>
              </div>
            </div>
        </div>
      )}
      </Layout>
    </>
  );
};

export default Home;
