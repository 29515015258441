import React from "react";
import { Link } from "react-router-dom";
import { useUser } from "../contexts/UserProvider";
import { jwtDecode } from "jwt-decode";
import { getCookie, setCookie, removeCookie } from "../config/coockies";
const Footer = () => {

  const { userData } = useUser()
  const token = getCookie("savage_token");
  let decodedToken
  if (token) {
    decodedToken = jwtDecode(token);
  }

  return (
    <>
      
        <footer className="bg-gray-800 text-white p-4 fixed bottom-0 w-full z-40">
          <div className="container mx-auto">
            <p>&copy; 2024 Group Hub. All rights reserved.</p>
            {decodedToken?.user?.firstname ? (<>
            <span className="rounded-lg fixed right-0 bottom-14 bg-[#005ae6] p-2" >Single chat, group chat, and private chat.</span>
           
            <div className="h-15 w-15 rounded-full fixed -bottom-3 right-0 transform -translate-x-1/2 -translate-y-1/2 flex justify-center items-center">
              <Link to="/chats"><img src="/assets/chat.gif" alt="chat" className="h-[45px] w-[45px] rounded-full bg-white relative z-[9999] border-[#005ae6] border-[3px]"/></Link>
            </div>
            </>
          ) : null}
          </div>
        </footer>
      
    </>
  );
};

export default Footer;