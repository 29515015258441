import axios from "axios";
import React, { useEffect, useState } from "react";
import { apiurl } from "../config/config";
import { useUser } from "../contexts/UserProvider";
import { toast } from "react-toastify";
import LoaderSpiner from "./loader";
import { getCookie } from "../config/coockies";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import dayjs from "dayjs";

const UpdateProfile = ({ updateProfile, setUpdateProfile, getprofiledata }) => {
  const { userData } = useUser();
  const token = getCookie("savage_token");

  const [handle, setHandle] = useState(false);
  const [updateData, setUpdateData] = useState({
    firstname: "",
    lastname: "",
    dob: "",
    organization: "",
    designation: "",
    email: "",
    location: "",
    userHandle: "",
    bio: "",
    userType:"",
    phone:""
  });
  const [loading, setLoading] = useState(false);
  const initialerror = {
    userHandle: false,
  };
  const [error, setError] = useState(initialerror);

  useEffect(() => {
    setUpdateData({
      firstname: getprofiledata.firstname,
      lastname: getprofiledata.lastname,
      dob: getprofiledata.dob,
      organization: getprofiledata.organization,
      designation: getprofiledata.designation,
      email: getprofiledata.email,
      location: getprofiledata.location,
      userHandle: getprofiledata.userHandle,
      bio: getprofiledata.bio,
      userType:getprofiledata.userType,
      phone:getprofiledata.phone
    });
  }, [getprofiledata]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "userHandle") {
      const alphanumericRegex = /^[a-zA-Z0-9]*$/;
      if (alphanumericRegex.test(value)) {
        setHandle(false);
        userhandleChange(e.target.value);
        setError({ ...error, [e.target.name]: "valid" });
      }
      else{
        setHandle(true)
        setError({ ...error, [e.target.name]: true });
      }

    }
    setUpdateData({ ...updateData, [name]: value });
  };
  const handleDateChange = (newDate) => {
    const formattedDate = newDate ? newDate.format("MM-DD-YYYY") : "";
    setUpdateData((prevData) => ({ ...prevData, dob: formattedDate }));
    // console.log("newDate", newDate);
  };

  const handleUpdateProfile = async () => {
    const {firstname,lastname,dob,organization,designation,email,location,userHandle,bio,userType} = updateData

    if (!bio) {
      toast.error("Bio cannot be blank");
      return; 
    }else if (!firstname || !lastname) {
      toast.error("First name and last name cannot be blank");
      return; 
    }else if(handle){
      const message = error.userHandle !== "valid" && error.userHandle === true ? "User handle must be unique and contain only letters and numbers." : "User handle already taken. Try a different one!";
      toast.error(message);
      return; 
    }else if (!/^[a-zA-Z0-9]+$/.test(userHandle)) {
      toast.error("User handle must be unique and contain only letters and numbers.");
      setHandle(true);
      setError({ ...error, userHandle: true });
      setLoading(false);
      return;
    }else{
      setLoading(true);
      try {
        const UserId = { id: userData._id };
        const response = await axios.put(
          `${apiurl}/user/update/${UserId.id}`,
          updateData,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: token,
            },
          }
        );
        // console.log("response", response);
        if (response.status === 200) {
          setLoading(false);
          toast.success("Profile Updated Successfully");
          setUpdateProfile(false);
        } else {
          toast.error("Failed to Update Data");
          setLoading(false);
        }
      } catch (error) {
        console.error("Profile update error", error);
        setLoading(false);
      }
    }
  };

  const userhandleChange = async (value) => {
    // Handle registration API call
    try {
      const response = await axios({
        url: `${apiurl}/user/checkhandle/${value}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      // console.log("user handle response >>>>>>", response);
      if (response.data.userfound === true) {
        setHandle(true);
      } else {
        setHandle(false);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div>
      {loading === true ? <LoaderSpiner text="Loading" /> : null}
      {updateProfile ? (
        <div className="bg-white p-8 rounded shadow w-full m-auto my-4 update_Profile">
          <div className="flex justify-between">
            <h2 className="text-2xl font-semibold mb-4">Update Profile</h2>
            <button
              onClick={() => setUpdateProfile(false)}
              className="text-2xl font-semibold border border-1 px-3 rounded-lg py-0 hover:bg-slate-200"
            >
              X
            </button>
          </div>
          <div className="w-full m-auto">
            <div className="flex flex-wrap justify-between">
              <div className="mb-4 relative w-full sm:w-[47%]">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="firstname"
                >
                  First Name
                </label>
                <input
                  className="w-full border border-gray-300 p-2 rounded-md focus:outline-none focus:border-blue-500"
                  type="text"
                  placeholder="First Name"
                  name="firstname"
                  value={updateData.firstname}
                  onChange={handleChange}
                />
              </div>

              <div className="mb-4 relative w-full sm:w-[47%]">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="lastname"
                >
                  Last Name
                </label>
                <input
                  className="w-full border border-gray-300 p-2 rounded-md focus:outline-none focus:border-blue-500"
                  type="text"
                  placeholder="Last Name"
                  name="lastname"
                  value={updateData.lastname}
                  onChange={handleChange}
                />
              </div>

              <div className="mb-4 relative w-[100%] sm:w-[47%]">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="dob"
                >
                  Date of Birth
                </label>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DesktopDatePicker
                    value={
                      updateData.dob
                        ? dayjs(updateData.dob, "MM-DD-YYYY")
                        : null
                    }
                    onChange={handleDateChange}
                    format="MM-DD-YYYY"
                    views={["year", "month", "day"]}
                    renderInput={(params) => (
                      <input
                        {...params.inputProps}
                        placeholder="Date of Birth"
                        className="w-[100%] mx-0 bg-[#EFF5FF] placeholder-slate-700 focus:bg-[#EFF5FF] outline-none m-auto block p-2 border border-[#f0f0f0]"
                      />
                    )}
                  />
                </LocalizationProvider>
              </div>

             

              <div className="mb-4 relative w-full sm:w-[47%]">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="email"
                >
                  Email
                </label>
                <input
                  className="w-full cursor-not-allowed border border-gray-300 p-2 rounded-md focus:outline-none focus:border-blue-500"
                  type="email"
                  placeholder="Email"
                  name="email"
                  value={updateData.email}
                  onChange={handleChange}
                  readOnly
                />
              </div>
              <div className="mb-4 relative w-full sm:w-[47%]">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="phone"
              >
                Phone
              </label>
              <input
                className="w-full cursor-not-allowed border border-gray-300 p-2 rounded-md focus:outline-none focus:border-blue-500"
                type="text"
                placeholder="Phone"
                name="phone"
                value={updateData.phone}
                onChange={handleChange}
                readOnly
              />
            </div>

              <div className="mb-4 relative w-full sm:w-[47%]">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="location"
                >
                  Location
                </label>
                <input
                  className="w-full border border-gray-300 p-2 rounded-md focus:outline-none focus:border-blue-500"
                  type="text"
                  placeholder="Location"
                  name="location"
                  value={updateData.location}
                  onChange={handleChange}
                />
              </div>

              <div className="mb-4 relative w-full sm:w-[47%]">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="location"
                >
                  User Handle
                </label>
                <input
                  // className="w-full border border-gray-300 p-2 rounded-md focus:outline-none focus:border-blue-500 "
                  className={`w-full border border-gray-300 p-2 rounded-md focus:outline-none focus:border-blue-500 placeholder-slate-700 outline-none m-auto block ${
                  !handle ? "border-[#f0f0f0]" : "border-[#ff0000]"
                }`}
                  type="text"
                  placeholder="userHandle"
                  name="userHandle"
                  value={updateData.userHandle}
                  onChange={handleChange}
                  // readOnly={true}
                />
                <p className={`${!handle ? "text-[#3cd70b]" : "text-[#ff0000]"} text-xs text-left ml-2`}>{handle?"Not Available":"Available"}</p>
              </div>
              <div className="mb-4 relative w-full sm:w-[47%]">
              <label className="block text-gray-700 text-sm font-bold mb-2">
                Account Visibility
              </label>
              <div className="flex  items-center space-x-4">
                <div className="flex h-[20px]">
                  <input
                    id="private"
                    type="radio"
                    name="userType"
                    value="private"
                    checked={updateData.userType === "private"}
                    onChange={handleChange}
                    className="mr-2"
                  />
                  <label htmlFor="private" className="text-gray-700 text-sm">
                    Private
                  </label>
                </div>
            
                <div className="flex h-[20px]">
                  <input
                    id="public"
                    type="radio"
                    name="userType"
                    value="public"
                    checked={updateData.userType === "public"}
                    onChange={handleChange}
                    className="mr-2"
                  />
                  <label htmlFor="public" className="text-gray-700 text-sm">
                    Public
                  </label>
                </div>
              </div>
              </div>
              <div className="mb-4 relative w-full">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="Bio"
                >
                  Bio
                </label>
                <input
                  className="w-full border border-gray-300 p-2 rounded-md focus:outline-none focus:border-blue-500"
                  type="text"
                  placeholder="Bio"
                  name="bio"
                  value={updateData.bio}
                  onChange={handleChange}
                />
              </div>
             
            
            </div>
          </div>
          <div className="flex items-center justify-center">
            <button
              className="bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 focus:outline-none focus:shadow-outline-blue"
              onClick={handleUpdateProfile}
            >
              Update Profile
            </button>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default UpdateProfile;
